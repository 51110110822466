var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$showDevThings)?_c('div',{class:['dev-layer-activator', {
    'dev-layer-activator--white-page': _vm._.get(_vm.$route.meta, 'layout.isWhite'),
    'dev-layer-activator--two-pane-layout': _vm._.get(_vm.$route.meta, 'layout.isTwoPane'),
    'sidebar-is-collapsed': _vm.isSidebarCollapsed,
  }]},[_c('CyButton',{class:['dev-layer-activator__btn', {
      'dev-layer-activator__btn--show-warn-blip': _vm.hasMockedEndpoints,
      'dev-layer-activator__btn--show-error-blip': _vm.hasTooManyPermCalls,
    }],attrs:{"theme":"grey","variant":"tertiary"},nativeOn:{"click":function($event){return (function () { return _vm.TOGGLE_DEV_LAYER(true); }).apply(null, arguments)}}},[_c('CyTooltip',{attrs:{"disabled":!_vm.isSidebarCollapsed,"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"dev-layer-activator__btn-icon",attrs:{"size":"34"}},on),[_vm._v(" monitor_heart_rounded ")])]}}],null,false,562026628)},[_c('span',{staticClass:"tooltip-text"},[_vm._v("🦄 Open dev layer ✨")]),(_vm.hasTooManyPermCalls)?_c('span',{staticClass:"tooltip-text--error"},[_vm._v(" Too many permission calls! ")]):_vm._e(),(_vm.hasMockedEndpoints)?_c('span',{staticClass:"tooltip-text--warn"},[_vm._v(" This page has mocked endpoints ")]):_vm._e()]),(!_vm.isSidebarCollapsed)?_c('span',{staticClass:"dev-layer-activator__btn-text"},[_vm._v(" 🦄 Dev Layer ")]):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }