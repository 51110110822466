<template>
  <div
    class="cy-global-alerts"
    data-cy="global-alerts">
    <template v-for="({ timestamp, title, content, type }, index) of allAlertsOrderedByTimestamp">
      <CyAlert
        v-bind="{ theme: type, title, content }"
        :key="`${type}:${index}:${timestamp}`"
        :ref="`${type}:${index}:${timestamp}`"
        class="elevation-1"
        closeable
        data-cy="snack-modal"
        no-bottom-margin
        @close="HIDE_ALERT({ type, timestamp })"/>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export const TIMEOUT = 2000

export default {
  name: 'CyAlertModal',
  computed: {
    ...mapGetters('alerts', [
      'allAlertsOrderedByTimestamp',
    ]),
  },
  watch: {
    '$route.name' () {
      setTimeout(() => this.RESET_ALERT_STATE('error'), TIMEOUT)
    },
  },
  methods: {
    ...mapActions('alerts', [
      'HIDE_ALERT',
    ]),
    ...mapMutations('alerts', [
      'RESET_ALERT_STATE',
    ]),
  },
}
</script>

<style lang="scss" scoped>
.cy-global-alerts {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  width: 384px;
  max-height: 100vh;
  overflow: hidden;

  &:not(:empty) {
    padding: 1em;
  }

  &--hidden {
    display: none;
  }
}
</style>
