<template>
  <div
    v-if="$showDevThings && showDevLayer"
    ref="overlay"
    class="dev-layer"
    tabindex="0"
    @keydown.esc="closeDevLayer">
    <header class="dev-layer__header">
      <h1>// dev layer //</h1>

      <div class="links">
        <a
          v-for="{ href, text } of links"
          :key="text"
          v-bind="{ href }"
          target="_blank">
          {{ text }}
        </a>
      </div>

      <CyButton
        icon-only
        icon="close"
        theme="grey"
        @click="closeDevLayer"/>
    </header>

    <main class="dev-layer__content">
      <section class="section actions pb-8">
        <CyDevShowDevThingsBtn/>
      </section>

      <section class="section features">
        <h2 class="section__header">
          Feature flags
          <CyTooltip
            right
            transition="slide-x-transition">
            <template #activator="{ on }">
              <v-icon
                class="clickable"
                dark
                color="darkgrey"
                v-on="on">
                info_outline
              </v-icon>
            </template>
            When changed, will trigger a reload to repopulate routes
          </CyTooltip>
        </h2>
        <template v-if="!_.isEmpty($showFeature)">
          <v-checkbox
            v-for="(value, key) in $showFeature"
            :key="`show-feature--${key}`"
            :class="['feature-flag', { 'feature-flag--enabled': value }]"
            :label="key"
            :color="value ? 'secondary' : undefined"
            :disabled="$isBetaFeature[key]"
            :readonly="$isBetaFeature[key]"
            :ripple="!$isBetaFeature[key]"
            no-messages
            dark
            dense
            hide-details
            :secondary="value"
            :input-value="value"
            @click="() => $isBetaFeature[key] || TOGGLE_DEV_FEATURE({ key, value: !value })"/>
        </template>
        <em v-else>none</em>
      </section>

      <section class="section mocked-endpoints">
        <h2 class="section__header">
          Mocked endpoints
          <CyTooltip
            right
            transition="slide-x-transition">
            <template #activator="{ on }">
              <v-icon
                class="clickable"
                dark
                color="darkgrey"
                v-on="on">
                info_outline
              </v-icon>
            </template>
            Endpoints being mocked on this page
          </CyTooltip>
        </h2>
        <ul v-if="hasMockedEndpoints">
          <li
            v-for="(endpoint, index) of mockedEndpointsCalled"
            :key="`${endpoint}-${index}`">
            <span class="warn-txt">{{ endpoint }}</span>
          </li>
        </ul>
        <em
          v-else
          class="darkgrey--text">
          none
        </em>
      </section>

      <section class="section permissions-checked">
        <h2 class="section__header">
          Permissions
          <CyTooltip
            right
            transition="slide-x-transition">
            <template #activator="{ on }">
              <v-icon
                class="clickable"
                dark
                color="darkgrey"
                v-on="on">
                info_outline
              </v-icon>
            </template>
            All permissions fetched for this page via <code>can_do</code>
          </CyTooltip>
        </h2>
        <template v-if="hasPermissions">
          <h3 class="section__subheader--perms-in-order">
            In order
          </h3>
          <ol class="section__sub-content--perms-in-order">
            <li
              v-for="({ action, canonical }, index) of permissionsChecked"
              :key="`${action}-${index}`">
              [
              <template v-if="canonical">
                <span
                  :class="[
                    'action-name',
                    getPermissionColor(action, index),
                    { 'action-name--selected': action === selected.permissionAction },
                  ]">
                  {{ action }}
                </span>, {{ canonical }}
              </template>
              <template v-else>
                <span
                  :class="[
                    'action-name',
                    getPermissionColor(action, index),
                    { 'action-name--selected': action === selected.permissionAction },
                  ]">
                  {{ action }}
                </span>
              </template>
              ]
            </li>
          </ol>

          <h3 class="section__subheader--perms-total">
            Total calls
          </h3>
          <span
            :class="['section__sub-content--perms-total', {
              'bad-txt': permissionsChecked.length > 50,
              'warn-txt': permissionsChecked.length > 25,
            }]">
            {{ permissionsChecked.length }}
          </span>

          <h3 class="section__subheader--perms-counts">
            Counts
          </h3>
          <ul class="section__sub-content--perms-counts">
            <li
              v-for="(count, action) of permissionsCount"
              :key="action"
              class="permission-count">
              <span :class="['count', { 'count--multiple': count > 1 }]">
                {{ count }}
              </span>
              <span
                :class="[
                  'action-name hoverable clickable',
                  getPermissionColor(action),
                  { 'action-name--selected': action === selected.permissionAction },
                ]"
                @click="() => selected.permissionAction === action
                  ? clearPermissionAction()
                  : selectPermissionAction(action)">
                {{ action }}
              </span>
            </li>
          </ul>
        </template>
        <em
          v-else
          class="permissions-empty darkgrey--text">
          none
        </em>
      </section>
    </main>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import CyDevShowDevThingsBtn from '@/components/dev/show-dev-things-btn.vue'

export default {
  name: 'CyDevLayer',
  components: {
    CyDevShowDevThingsBtn,
  },
  data: () => ({
    selected: {
      permissionAction: '',
    },
  }),
  computed: {
    ...mapState('dev', {
      mockedEndpointsCalled: (state) => state.mockedEndpointsCalled,
      permissionsChecked: (state) => state.permissionsChecked,
      showDevLayer: (state) => state.showDevLayer,
      showDevFeature: (state) => state.showDevFeature,
    }),
    ...mapGetters('dev', [
      'hasMockedEndpoints',
      'permissionsCount',
    ]),
    links () {
      const localBElinks = this.$isLocalBE && [
        {
          href: 'http://localhost:8025',
          text: 'LocalBE Emails',
        },
        {
          href: 'https://github.com/cycloidio/youdeploy-frontend-web/wiki/FAQ#how-do-i-set-up-the-fe-to-run-with-a-local-api',
          text: 'LocalBE Docs',
        },
      ]
      const stagingOrDevLinks = this.$isStagingOrDev && [
        {
          href: 'https://docs.staging.cycloid.io/api/index.html',
          text: 'Staging api docs',
        },
        {
          href: 'https://github.com/cycloidio/youdeploy-http-api/blob/develop/services/authorization/action/actions.json',
          text: 'Staging actions.json',
        },
      ]
      const betaLinks = this.$isBeta && [
        {
          href: 'https://docs.cycloid.io/api/index.html',
          text: 'Prod api docs',
        },
        {
          href: 'https://github.com/cycloidio/youdeploy-http-api/blob/master/services/authorization/action/actions.json',
          text: 'Prod actions.json',
        },
      ]

      return [
        ...localBElinks || [],
        ...stagingOrDevLinks || [],
        ...betaLinks || [],
      ]
    },
    hasPermissions () {
      return !_.isEmpty(this.permissionsChecked)
    },
  },
  watch: {
    $showDevThings (isVisible) {
      if (!isVisible) this.RESET_DEV_STATE('showDevLayer')
    },
    showDevFeature: {
      handler () {
        location.reload()
      },
      deep: true,
    },
    async showDevLayer (isVisible) {
      if (!isVisible) return document.querySelector('html').classList.remove('force-no-scroll')

      await this.$nextTick()
      this.$refs.overlay?.focus()
      document.querySelector('html').classList.add('force-no-scroll')
    },
  },
  methods: {
    ...mapMutations('dev', [
      'RESET_DEV_STATE',
      'TOGGLE_DEV_FEATURE',
      'TOGGLE_DEV_LAYER',
    ]),
    closeDevLayer () {
      this.TOGGLE_DEV_LAYER(false)
      this.$resetData()
    },
    isFirstCalled (action, index) {
      const firstIndexOfAction = _.indexOf(_.map(this.permissionsChecked, 'action'), action)
      return firstIndexOfAction === index
    },
    selectPermissionAction (action) {
      this.selected.permissionAction = action
    },
    clearPermissionAction () {
      this.selected.permissionAction = ''
    },
    getPermissionColor (action, index) {
      const count = this.permissionsCount?.[action] ?? 1
      const isFirstCalled = this.isFirstCalled(action, index)
      if (isFirstCalled || count === 1) return 'ok-txt'
      if (count <= 5) return 'near-warn-txt'
      return 'bad-txt'
    },
  },
}
</script>

<style lang="scss">
html.force-no-scroll {
  overflow-y: hidden !important;
}
</style>

<style lang="scss" scoped>
$good: cy-get-color("success");
$warn: cy-get-color("warning");
$near-warn: greenyellow;
$bad: cy-get-color("error");
$header: whitesmoke;
$subheader: cornflowerblue;
$selected-feature-flag: cy-get-color("secondary");

%flex-column {
  display: flex;
  flex-direction: column;
}

.dev-layer {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  align-self: center;
  width: 100vw;
  height: 100vh;
  padding: 3em;
  background: cy-get-color("black", $alpha: 0.9);
  color: cy-get-color("white");
  font-family: monospace;

  @extend %flex-column;

  &__header {
    display: flex;
    justify-content: space-between;

    .links {
      display: flex;
      align-items: center;
      margin-right: 2em;
      margin-left: auto;

      a {
        padding: 0 1em;

        &:not(:hover, :focus) {
          color: aqua;
        }

        &:not(:last-child) {
          border-right: 1px solid cy-get-color("grey", "dark-3");
        }
      }
    }
  }

  &__content {
    $header-height: 42px;
    $padding: 6em;

    height: calc(100vh - #{$header-height} - #{$padding});

    @extend %flex-column;
  }

  .section {
    padding-top: 2em;

    &__header {
      color: $header;
    }

    &__subheader {
      color: $subheader;
    }

    &.features ::v-deep {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .v-input--is-label-active,
      .v-input--is-label-active .v-label {
        color: $selected-feature-flag;
      }

      .v-input--is-disabled {
        .v-input--selection-controls__input,
        .v-label {
          opacity: 0.4;
          cursor: not-allowed;
        }
      }
    }

    &.permissions-checked {
      display: grid;
      grid-template-areas:
        "title            ."
        "in-order-title   total-title"
        "in-order-content total-content"
        "in-order-content counts-title"
        "in-order-content counts-content";
      grid-template-rows: auto 2em 4em 2em auto;
      align-self: flex-start;
      overflow-y: hidden;
      column-gap: 3em;

      .permissions-empty {
        grid-area: in-order-title;
      }

      .section {
        &__header {
          grid-area: title;
        }

        &__subheader {
          &--perms-in-order,
          &--perms-total,
          &--perms-counts {
            @extend .section__subheader;
          }

          &--perms-in-order {
            grid-area: in-order-title;
          }

          &--perms-total {
            grid-area: total-title;
          }

          &--perms-counts {
            grid-area: counts-title;
          }
        }

        &__sub-content {
          &--perms-in-order,
          &--perms-counts {
            padding-right: 2em;
            padding-bottom: 1px;
            padding-left: 0;
            list-style: none;

            @extend %cy-scrollbars-always-show;
            @extend %cy-scrollbars-light;

            li {
              color: cy-get-color("grey", "dark-3");
              white-space: nowrap;
            }
          }

          &--perms-in-order {
            grid-area: in-order-content;
          }

          &--perms-total {
            grid-area: total-content;
            font-size: 1.5rem;
          }

          &--perms-counts {
            grid-area: counts-content;

            .count {
              padding-right: 4px;

              &--multiple {
                color: cy-get-color("white");
              }
            }
          }
        }
      }

      .action-name {
        border: 2px solid transparent;
        border-radius: 4px;

        &--selected,
        &.hoverable:hover {
          color: cy-get-color("black");
          white-space: pre;
        }

        &--selected.ok-txt,
        &.hoverable.ok-txt:hover {
          border-color: $good;
          background: $good;
        }

        &--selected.near-warn-txt,
        &.hoverable.near-warn-txt:hover {
          border-color: $near-warn;
          background: $near-warn;
        }

        &--selected.warn-txt,
        &.hoverable.warn-txt:hover {
          border-color: $warn;
          background: $warn;
        }

        &--selected.bad-txt,
        &.hoverable.bad-txt:hover {
          border-color: $bad;
          background: $bad;
        }
      }
    }
  }

  ::v-deep .show-dev-things-btn {
    position: initial !important;
  }

  .near-warn-txt { color: $near-warn; }
  .warn-txt { color: $warn; }
  .ok-txt { color: $good; }
  .bad-txt { color: $bad; }
}
</style>
