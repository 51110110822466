var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$showDevThings && _vm.showDevLayer)?_c('div',{ref:"overlay",staticClass:"dev-layer",attrs:{"tabindex":"0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.closeDevLayer.apply(null, arguments)}}},[_c('header',{staticClass:"dev-layer__header"},[_c('h1',[_vm._v("// dev layer //")]),_c('div',{staticClass:"links"},_vm._l((_vm.links),function(ref){
var href = ref.href;
var text = ref.text;
return _c('a',_vm._b({key:text,attrs:{"target":"_blank"}},'a',{ href: href },false),[_vm._v(" "+_vm._s(text)+" ")])}),0),_c('CyButton',{attrs:{"icon-only":"","icon":"close","theme":"grey"},on:{"click":_vm.closeDevLayer}})],1),_c('main',{staticClass:"dev-layer__content"},[_c('section',{staticClass:"section actions pb-8"},[_c('CyDevShowDevThingsBtn')],1),_c('section',{staticClass:"section features"},[_c('h2',{staticClass:"section__header"},[_vm._v(" Feature flags "),_c('CyTooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"clickable",attrs:{"dark":"","color":"darkgrey"}},on),[_vm._v(" info_outline ")])]}}],null,false,2308029919)},[_vm._v(" When changed, will trigger a reload to repopulate routes ")])],1),(!_vm._.isEmpty(_vm.$showFeature))?_vm._l((_vm.$showFeature),function(value,key){return _c('v-checkbox',{key:("show-feature--" + key),class:['feature-flag', { 'feature-flag--enabled': value }],attrs:{"label":key,"color":value ? 'secondary' : undefined,"disabled":_vm.$isBetaFeature[key],"readonly":_vm.$isBetaFeature[key],"ripple":!_vm.$isBetaFeature[key],"no-messages":"","dark":"","dense":"","hide-details":"","secondary":value,"input-value":value},on:{"click":function () { return _vm.$isBetaFeature[key] || _vm.TOGGLE_DEV_FEATURE({ key: key, value: !value }); }}})}):_c('em',[_vm._v("none")])],2),_c('section',{staticClass:"section mocked-endpoints"},[_c('h2',{staticClass:"section__header"},[_vm._v(" Mocked endpoints "),_c('CyTooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"clickable",attrs:{"dark":"","color":"darkgrey"}},on),[_vm._v(" info_outline ")])]}}],null,false,2308029919)},[_vm._v(" Endpoints being mocked on this page ")])],1),(_vm.hasMockedEndpoints)?_c('ul',_vm._l((_vm.mockedEndpointsCalled),function(endpoint,index){return _c('li',{key:(endpoint + "-" + index)},[_c('span',{staticClass:"warn-txt"},[_vm._v(_vm._s(endpoint))])])}),0):_c('em',{staticClass:"darkgrey--text"},[_vm._v(" none ")])]),_c('section',{staticClass:"section permissions-checked"},[_c('h2',{staticClass:"section__header"},[_vm._v(" Permissions "),_c('CyTooltip',{attrs:{"right":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"clickable",attrs:{"dark":"","color":"darkgrey"}},on),[_vm._v(" info_outline ")])]}}],null,false,2308029919)},[_vm._v(" All permissions fetched for this page via "),_c('code',[_vm._v("can_do")])])],1),(_vm.hasPermissions)?[_c('h3',{staticClass:"section__subheader--perms-in-order"},[_vm._v(" In order ")]),_c('ol',{staticClass:"section__sub-content--perms-in-order"},_vm._l((_vm.permissionsChecked),function(ref,index){
var action = ref.action;
var canonical = ref.canonical;
return _c('li',{key:(action + "-" + index)},[_vm._v(" [ "),(canonical)?[_c('span',{class:[
                  'action-name',
                  _vm.getPermissionColor(action, index),
                  { 'action-name--selected': action === _vm.selected.permissionAction } ]},[_vm._v(" "+_vm._s(action)+" ")]),_vm._v(", "+_vm._s(canonical)+" ")]:[_c('span',{class:[
                  'action-name',
                  _vm.getPermissionColor(action, index),
                  { 'action-name--selected': action === _vm.selected.permissionAction } ]},[_vm._v(" "+_vm._s(action)+" ")])],_vm._v(" ] ")],2)}),0),_c('h3',{staticClass:"section__subheader--perms-total"},[_vm._v(" Total calls ")]),_c('span',{class:['section__sub-content--perms-total', {
            'bad-txt': _vm.permissionsChecked.length > 50,
            'warn-txt': _vm.permissionsChecked.length > 25,
          }]},[_vm._v(" "+_vm._s(_vm.permissionsChecked.length)+" ")]),_c('h3',{staticClass:"section__subheader--perms-counts"},[_vm._v(" Counts ")]),_c('ul',{staticClass:"section__sub-content--perms-counts"},_vm._l((_vm.permissionsCount),function(count,action){return _c('li',{key:action,staticClass:"permission-count"},[_c('span',{class:['count', { 'count--multiple': count > 1 }]},[_vm._v(" "+_vm._s(count)+" ")]),_c('span',{class:[
                'action-name hoverable clickable',
                _vm.getPermissionColor(action),
                { 'action-name--selected': action === _vm.selected.permissionAction } ],on:{"click":function () { return _vm.selected.permissionAction === action
                ? _vm.clearPermissionAction()
                : _vm.selectPermissionAction(action); }}},[_vm._v(" "+_vm._s(action)+" ")])])}),0)]:_c('em',{staticClass:"permissions-empty darkgrey--text"},[_vm._v(" none ")])],2)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }