import { render, staticRenderFns } from "./layer.vue?vue&type=template&id=4fa6739f&scoped=true"
import script from "./layer.vue?vue&type=script&lang=js"
export * from "./layer.vue?vue&type=script&lang=js"
import style0 from "./layer.vue?vue&type=style&index=0&id=4fa6739f&prod&lang=scss"
import style1 from "./layer.vue?vue&type=style&index=1&id=4fa6739f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fa6739f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCheckbox,VIcon})
