<template>
  <router-link
    class="ma-4 d-flex align-center company-logo"
    data-cy="company-logo-link"
    :to="linkRoute"
    :disabled="disableLink"
    :is="disableLink ? 'span' : 'router-link'">
    <CyAppLogo/>
    <h2
      v-if="$showFeature.whiteLabel"
      data-cy="company-logo-name"
      class="ml-3 company-logo__name">
      {{ displayName }}
    </h2>
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CyCompanyLogo',
  computed: {
    ...mapGetters([
      'displayName',
    ]),
    disableLink () {
      return this.linkRoute.name === this.$route.name
    },
    linkRoute () {
      const { orgCanonical = this.orgCanonical } = this.$route.params
      if (this.isAuthenticated && !orgCanonical) return { name: 'organizations' }
      if (this.isAuthenticated) return { name: 'dashboard', params: { orgCanonical } }
      return this.$showFeature.whiteLabel && orgCanonical
        ? { name: 'login', params: { orgCanonical } }
        : { name: 'login' }
    },
  },
}
</script>

<style lang="scss" scoped>
$title-color: var(--appearance-text-color);

.company-logo {
  z-index: 2;
  color: $title-color;

  &:hover,
  &:active,
  &:visited {
    color: $title-color;
  }

  &__name {
    display: inline-block;
    font-size: $font-size-h3;
  }
}
</style>
